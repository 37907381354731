import { Link } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

interface ChapterProps {
  title: string;
  to: string;
}

const Container = tw(
  Link,
)`py-2 text-base md:text-lg font-light hover:text-purple-600 hover:underline`;

const Chapter: React.FC<ChapterProps> = (props) => {
  return <Container to={props.to}>· {props.title} →</Container>;
};

export default Chapter;
