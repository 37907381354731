import React from 'react';
import Chapter from '../../components/chapter';
import CatalogLayout from '../../layouts/catalog';

export default () => (
  <CatalogLayout title="了解我们">
    <Chapter title="宗旨" to="/about/code-of-conduct" />
    <Chapter title="加入我们，共同成长" to="/about/join-us" />
  </CatalogLayout>
);
