import { Link } from 'gatsby';
import tw from 'twin.macro';
import SEO from '../components/seo';
import LandingLayout from './landing';

interface CatalogLayoutProps {
  title?: string;
  children?: React.ReactNode;
}

const TitleContainer = tw.div`relative max-w-screen-lg mx-auto pt-6 md:pt-12 px-6 text-3xl md:text-5xl text-black font-bold text-center`;
const BackToHome = tw(Link)`absolute left-6 text-sm md:text-base hover:text-purple-600 select-none`;
const ContentContainer = tw.div`flex flex-col items-center mx-auto px-8 py-8 max-w-screen-md`;

const CatalogLayout: React.FC<CatalogLayoutProps> = ({ title, children }) => (
  <LandingLayout>
    <SEO title={title} />
    <TitleContainer>
      <BackToHome to="/">← 回到首页</BackToHome>
      <span className="strong">{title}</span>
    </TitleContainer>
    <ContentContainer>{children}</ContentContainer>
  </LandingLayout>
);

export default CatalogLayout;
